import React from "react";
import { Button } from "../../atomes";
import { Auth } from "aws-amplify";

const LoginWithProvider = () => {
  
  const handelGoogleLogin = async e => {
    e.preventDefault();
    console.log("in handle google log in", e);
    await Auth.federatedSignIn({ provider: "Google" });
  };

  const handelFacebookLogin = e => {
    e.preventDefault();
    Auth.federatedSignIn({ provider: "Facebook" });
  };
  return (
    <div className="login-form--social">
      <Button type="google" handelClick={handelGoogleLogin} iconName="google">
        google
      </Button>
      <Button
        type="facebook"
        handelClick={handelFacebookLogin}
        iconName="facebook"
      >
        Facebook
      </Button>
    </div>
  );
};

export default LoginWithProvider;
